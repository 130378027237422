import AppointmentCard from "component/Card/AppointmentCard"
import {observer} from "mobx-react"
import React, {Component} from "react"
import {Button, Checkbox, Loader, Message, Modal} from "semantic-ui-react"
// import appointmentStore from "store/appointmentStore"
import moment from "moment"
import config from "store/store.config"
import {TimePicker} from "./TimePicker"
import {keys, map} from "lodash-es"
import Icon from "../utils/Icon"
import {faCheckCircle} from "@fortawesome/pro-light-svg-icons"

window.moment = moment

const FORBIDDEN_DATES = ["2022-10-03", "2022-11-01"]
const SpecialDates = observer(class SpecialDates extends Component {

    render() {
        const {handleAppointment} = this.props
        const {specialAppointments} = appointmentStore
        const {boxenstop} = config.store
        return <div className="SpecialDates ">
            <div>
                {!specialAppointments && <Loader active/>}
                {specialAppointments && <div className="pt3 pb4">
                    <div>
                        {specialAppointments.map(({
                                                      date,
                                                      appointments
                                                  }) => {
                            const formatted = date.format("DD. MMMM, dddd")
                            return (
                                <AppointmentsModal
                                    title={`${formatted} Ihr Wunschtermin`}
                                    key={formatted}
                                    handleAppointment={handleAppointment}
                                    appointments={appointments}
                                    trigger={<Button className={"w-100"}>
                                        <div className="pa3">
                                            <div className="f5 black-80">{formatted}</div>
                                            <div className="black-60">{appointments.length} Termine verfügbar</div>
                                        </div>
                                    </Button>}/>
                            )
                        })
                        }
                    </div>
                </div>}
            </div>
        </div>
    }
})

export {SpecialDates}


export default class AppointmentCardGroup extends Component {

    render() {
        const {
            appointments,
            dateRequested,
            highlightDay,
            hideSecondary,
            onSelect,
            onlyWeekdays,
            itemsPerRow = 4
        } = this.props
        const {availableDays} = cartStore
        let appointmentKeys = []
        if (appointments) {
            appointmentKeys = keys(appointments)
            appointmentKeys.reverse()
        }

        if (dateRequested) {
            // // --> console.log( "filter Boxenstop Dates to", dateRequested )
            // TODO: THIS DOESNT WORK WITH TKP
            let newKeys = appointmentKeys.filter(date => {
                return moment(dateRequested).isSame(moment(date), "date")
            })

            if (newKeys.length !== 0) {
                appointmentKeys = newKeys
            }

            if (newKeys.length === 0) {
                console.error("FILTERED OUT ALL DATES -> The requested date did not match the appointments provided by /express-orders")
            }
        }
        return (
            <div>
                {map(appointmentKeys, (key, index) => {
                    let regularAppointments = appointments[key]
                        .sort((d0, d1) => {
                            if (d0.checkInTime.isBefore(d1.checkInTime)) {
                                return -1
                            }
                            return 1
                        })

                    const formatted = regularAppointments && regularAppointments[0].checkInTime.format("dddd DD. MMMM")
                    const isBoxenstopp = regularAppointments && moment(regularAppointments[0].checkInTime).isSame(highlightDay, "day")
                    const day = regularAppointments && regularAppointments[0].checkInTime.format("dd")
                    const isFallback = regularAppointments && regularAppointments[0].isFallback
                    const dateString = regularAppointments && regularAppointments[0].checkInTime.format("yyyy-MM-DD")
                    const dayNumber = regularAppointments && regularAppointments[0].checkInTime.day()
                    const isForbiddenDay = FORBIDDEN_DATES.includes(dateString)
                    const isAllowed =  (availableDays.length === 0 ) ||( availableDays.includes(dayNumber) && !isForbiddenDay)
                    const isWeekday = moment(regularAppointments[0].checkInTime).isoWeekday() !== 6

                    if (hideSecondary && !isBoxenstopp) return null
                    if (!isAllowed) return null

                    return <AppointmentsModal
                        title={formatted}
                        key={formatted}
                        handleAppointment={onSelect}
                        appointments={regularAppointments}
                        trigger={<Button basic className={"w-100 bg-white"} style={{marginBottom: "0.5rem"}}>
                            <div className=" flex flex-row justify-between items-center">
                                <div className={` f5 black-80 tl`}>
                                    {isBoxenstopp && <div className={"black-60 f6 pb1"}> Boxenstopp-Tag</div>}
                                    <div className={"pb1 b text-base leading-none"}>{formatted}</div>
                                    <div className={"black-60 f6"}>{regularAppointments.length} Termine verfügbar</div>
                                </div>
                                <div className="text-2xl">
                                    {isFallback && <Icon className={"text-gray-500 mr2"} icon={faCheckCircle}/>}
                                    {!isFallback && <Icon className={"blue mr2"} icon={faCheckCircle}/>}
                                </div>
                            </div>
                        </Button>}/>

                })}
            </div>
        )
    }
}


class AppointmentsModal extends Component {

    render() {
        const {
            trigger,
            title = "Terminauswahl",
            appointments,
            handleAppointment
        } = this.props

        return (
            <Modal trigger={trigger}>
                <div className="ModalHeader p-4 lg:p-6 lg:px-12 border-bottom md:text-2xl font-bold">
                    <div className="md:text-3xl text-xl">
                        {title} – Ihr Wunschtermin
                    </div>
                </div>
                <div className={"ModalContent p-4"}>
                    <div className="pt-4 md:px-8 px-4" tabIndex={-1}>
                        <AppointmentGroup handleAppointment={handleAppointment} appointments={appointments}/>
                    </div>
                </div>
            </Modal>
        )
    }
}


const AppointmentGroup = observer(function AppointmentGroup(props) {
        const [appointment, setAppointment] = React.useState(false)
        const [showCustomReleaseDate, setShowCustomReleaseDate] = React.useState(false)
        const {
            appointments,
            handleAppointment
        } = props
        const [iWait, setWaitingFlag] = React.useState(false)

        console.log({appointments})
        const hoursOnly = moment(appointment.checkInTime).format("HHmm")
        const onlyCarKeyTransfer = hoursOnly <= 730 || hoursOnly >= 1700

        console.log({
            hoursOnly,
            onlyCarKeyTransfer
        })
        return (
            <div>


                {appointment && <div>

                    <div className="pb3">
                        <h3 className={"ma0"}>Abgabe {moment(appointment.checkInTime).format("dddd")}</h3>
                        {onlyCarKeyTransfer && <Message>
                            <Message.Content>Wir nehmen Ihre Fahrzeugschlüssel am Empfang an.</Message.Content>
                        </Message>}
                    </div>
                    <div className="pb3">
                        <Button basic onClick={() => {
                            setAppointment(false)
                        }} fluid>
                            <div className="flex flex-row items-center justify-between">
                                <div className={"tl"}>
                                    <div className="f4 b pb1">{moment(appointment.checkInTime).format("HH:mm [Uhr]")}
                                        {/*– {moment( appointment.checkOutTime ).format( "HH:mm [Uhr]" )}*/}
                                    </div>
                                    <div>
                                        {!appointment.isSameDay && <span className="f6 black-60">Abholung {appointment.isSameDay ? "" : "am nächsten Tag"}</span>}
                                        {appointment.isSameDay && moment(appointment.checkInTime).format("ddd DD. MMM")}
                                    </div>
                                </div>
                                <div>ändern</div>
                            </div>
                        </Button>
                    </div>
                    {!appointment.isSameDay && <h3> Abholung ab {moment(appointment.checkOutTime).format("dddd")} </h3>}
                    {appointment.isSameDay && <h3>Abholung</h3>}


                  <div className="pb-4">
                     <Button
                         key={showCustomReleaseDate}
                         basic fluid
                                                            onClick={() => {
                                                                setShowCustomReleaseDate(!showCustomReleaseDate)
                                                            }}
                      >
                          <div className="flex flex-row items-center justify-between">
                              <div className={"tl"}>
                                  <div className="f4 b pb1">{moment(appointment.checkOutTime).format("HH:mm [Uhr]")}
                                      {/*– {moment( appointment.checkOutTime ).format( "HH:mm [Uhr]" )}*/}
                                  </div>
                                  <div>
                                      {!appointment.isSameDay && <span className="f6 black-60">Abholung {appointment.isSameDay ? "" : "am nächsten Tag"}</span>}
                                      {appointment.isSameDay && moment(appointment.checkOutTime).format("ddd DD. MMM")}
                                  </div>
                              </div>
                              <div>anpassen</div>
                          </div>

                      </Button>


                      {showCustomReleaseDate && <div className="pt-1">
                          <div className="flex flex-row items-center justify-start w-full relative">
                              <div className="  rounded-md w-full ">
                                  <TimePicker
                                      className={"w-full"}
                                      onChange={(time) => {
                                          console.log("CHANGE", time)
                                          const checkOutTime = moment(time)
                                          appointment.checkOutTime = checkOutTime
                                          checkoutStore.assign(appointment)
                                         setShowCustomReleaseDate(Math.random())
                                      }}
                                      defaultValue={moment(appointment.checkOutTime).toDate()}
                                      checkInTime={moment(appointment.checkInTime).toDate()}/>
                              </div>


                              {/*<div className="absolute top-0 right-2 bottom-0 flex flex-col justify-center">*/}
                              {/*    <div>Uhr</div>*/}
                              {/*</div>*/}
                          </div>
                      </div>}
                  </div>

                    <div className="pb3">


                        {!onlyCarKeyTransfer && <>
                            <Checkbox label={"Ich warte vor Ort auf mein Fahrzeug."}
                                      checked={iWait}
                                      onChange={() => {
                                          setWaitingFlag(!iWait)
                                          checkoutStore.assign({iWait: !iWait})
                                      }}/>
                            <p className="f6 black-70">In unserer Werkstatt Lounge: Wir halten einen ruhigen Platz, WLAN und einen Kaffee in unserer Filiale für Sie bereit.</p>
                        </>}
                        {/*<Button fluid basic>*/}
                        {/*    <div className={"flex flex-row justify-between items-center   "}>*/}
                        {/*        <div className={"b f4 "}>Lounge Kunde</div>*/}
                        {/*        <div>Ich möchte vor Ort warten</div>*/}
                        {/*    </div>*/}
                        {/*</Button>*/}
                    </div>

                    {/*<div className="pb3">*/}
                    {/*    <div>Der Termin dauert in etwa <span className="b">2:15 Std.</span></div>*/}
                    {/*    <div>Bitte geben Sie Ihr Fahrzeug jederzeit vor Beginn des ausgewählten Termins ab.</div>*/}
                    {/*</div>*/}

                    <Button
                        fluid
                        primary
                        onClick={(e) => {
                            handleAppointment(e, appointment, iWait)
                        }
                        }
                    >Weiter mit diesem Termin</Button>
                </div>}


                {!appointment && <React.Fragment>
                    <h3>Abgabe Termin</h3>
                    {appointments && appointments.map(appointment =>
                        <AppointmentCard onClick={(e, appointment) => {
                            setAppointment(appointment)
                        }
                        } appointment={appointment}
                                         key={appointment.checkInTime}/>)}
                </React.Fragment>}

            </div>
        )
    }
)

